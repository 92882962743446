<template>
  <div class="container form-wrapper">
    <div class="row"><h3>Заповнюй цю анкету, та отримай набір смаколиків від SoftServe</h3></div>
    <div class="row">
      <form class="col m12" @submit.prevent>
        <div class="row">
          <div class="input-field col m6 s12">
            <input
              autocomplete="off"
              v-model="firstName"
              id="first_name"
              type="text"
              :class="['validate', { invalid: errors.includes('firstName') }]"
              @focus="clearFieldErrors('firstName')"
              @input="clearFieldErrors('firstName')"
            />
            <label for="first_name">Ім’я*</label>
          </div>
          <div class="input-field col m6 s12">
            <input
              autocomplete="off"
              v-model="lastName"
              id="last_name"
              type="text"
              :class="['validate', { invalid: errors.includes('lastName') }]"
              @focus="clearFieldErrors('lastName')"
              @input="clearFieldErrors('lastName')"
            />
            <label for="last_name">Прізвище*</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col m6 s12">
            <input
              autocomplete="off"
              v-model="email"
              id="email"
              type="email"
              :class="['validate', { invalid: errors.includes('email') }]"
              @focus="clearFieldErrors('email')"
              @input="clearFieldErrors('email')"
            />
            <label for="email">Адреса робочої електронної пошти*</label>
          </div>
          <div class="input-field select-border col m6 s12">
            <select
              v-model="setValue"
              :class="[{ invalid: errors.includes('setValue') }]"
              @change="clearFieldErrors('setValue')"
            >
              <option v-for="(opt, $k) in setOptions" :key="$k">{{ opt }}</option>
            </select>
            <label v-if="!setValue.length">Оберіть набір, який бажаєте*</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col m6 s12">
            <input
              autocomplete="off"
              v-model="mobile"
              id="mobile"
              type="text"
              :class="['validate', { invalid: errors.includes('mobile') }]"
              @focus="clearFieldErrors('mobile')"
              @input="clearFieldErrors('mobile')"
              v-mask="'+38 ### ### ## ##'"
            />
            <label for="mobile">Вкажіть свій номер телефону*</label>
          </div>
          <div class="input-field col m6 s12">
            <DatePicker
              v-model="date"
              :editable="false"
              :disabled-date="disabledDates"
              placeholder="Вкажіть бажану дату доставки*"
              :input-class="errors.includes('date') ? 'invalid' : ''"
              @change="clearFieldErrors('date')"
              value-type="format"
              format="DD/MM/YYYY"
            />
          </div>
        </div>
        <div class="row">
          <div class="input-field col m6 s12">
            <input
              autocomplete="off"
              v-model="city"
              id="city"
              type="text"
              :class="['validate', { invalid: errors.includes('city') }]"
              @focus="clearFieldErrors('city')"
              @input="clearFieldErrors('city')"
            />
            <label for="city">Вкажіть місто доставки*</label>
          </div>
          <div class="input-field col m6 s12">
            <input
              autocomplete="off"
              v-model="address"
              id="address"
              type="text"
              :class="['validate', { invalid: errors.includes('address') }]"
              @focus="clearFieldErrors('address')"
              @input="clearFieldErrors('address')"
            />
            <label for="address">Вкажіть адресу доставки*</label>
          </div>
        </div>
        <div class="form-hint cv-form__hint agreement">
          <span
            >Натискаючи "Відправити", я погоджуюся з обробкою та зберіганням своїх персональних даних. Надання робочої
            електренної адреси було погоджено SoftServe.</span
          >&nbsp;<span class="agreement-btn" @click="showAgreement = !showAgreement">Детальніше</span>
        </div>
        <div class="btn-wrap">
          <button class="btn brand-btn" @click="handleSubmit" :disabled="loading">Відправити</button>
        </div>
        <transition name="slideFast">
          <div v-if="showAgreement" class="agreement-note">
            <span
              >Заповнюючи цю анкету та оформлюючи замовлення, я надаю однозначну згоду як суб’єкт персональних даних на
              їх обробку та зберігання ТОВ «СІЛЬПО-ФУД»: 02090, м. Київ, вул. Бутлерова, буд. 1 (володілець персональних
              даних) з метою здійснення доставки подарункового набору.</span
            >
          </div>
        </transition>
      </form>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'

const setOptions = ['Набір «FAMILY»', 'Набір «HEALTHY»', 'Набір «PARTY»']

import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore/lite'

const app = initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
})

async function getRegister(db) {
  const registerCol = collection(db, 'register')
  const registerSnapshot = await getDocs(registerCol)
  const registerList = registerSnapshot.docs.map((doc) => doc.data())
  return registerList
}

async function writeToRegister(db, payload) {
  return await addDoc(collection(db, 'register'), payload)
}

export default {
  name: 'Form',
  components: { DatePicker },
  async mounted() {
    const db = await getFirestore(app)
    this.$set(this, 'db', db)
  },
  data: () => ({
    date: '',
    setValue: '',
    setOptions: setOptions,
    firstName: '',
    lastName: '',
    mobile: '',
    city: '',
    address: '',
    email: '',

    rules: {
      firstName: ['required'],
      lastName: ['required'],
      mobile: ['required', 'mobile'],
      city: ['required'],
      email: ['required', 'email'],
      address: ['required'],
      setValue: ['required', `in:${setOptions.join('|')}`],
      date: ['required'],
    },
    errors: [],
    showAgreement: false,
    db: null,
    loading: false,
  }),
  methods: {
    disabledDates(date) {
      const startDate = new Date('January 15, 2022')
      const endDate = new Date('January 30, 2022')
      const disabledPastDays = Date.parse(date) < new Date().setHours(0, 0, 0, 0)
      return Date.parse(date) < startDate || Date.parse(date) > endDate || disabledPastDays
    },
    async handleSubmit() {
      if (this.loading) {
        return
      }
      this.loading = true

      if (!this.validate()) {
        this.loading = false
        return
      }

      const response = await writeToRegister(this.db, {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        date: this.date,
        mobile: this.mobile,
        set: this.setValue,
        city: this.city,
        address: this.address,
      })

      alert('Дякуємо за відправку, ми з тобою зв`яжемось.')

      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.date = ''
      this.mobile = ''
      this.setValue = ''
      this.city = ''
      this.address = ''

      this.loading = false

      window.location.href = 'https://silpo.ua/about/additional/softserve'
    },
    validate() {
      this.clearErrors()
      let valid = false

      const checkField = (value, rules) => {
        let validated = true

        for (const rule of rules) {
          const rulePart = rule.split(':')[0]
          const args = rule.split(':')[1]

          switch (rulePart) {
            case 'required':
              validated = this.checkRequired(value)
              break
            case 'email':
              validated = this.checkEmail(value)
              break
            case 'mobile':
              // validated = this.checkMobile(value)
              break
            case 'in':
              validated = this.checkIn(value, args)
              break
          }

          if (!validated) {
            break
          }
        }

        return validated
      }

      for (let field in this.rules) {
        valid = checkField(this[field], this.rules[field])

        if (!valid) {
          this.errors.push(field)
        }
      }

      return this.errors.length === 0
    },
    checkRequired(value) {
      return value && value.toString().length > 0
    },
    checkEmail(value) {
      return new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      ).test(value)
    },
    checkIn(value, args) {
      const argsArr = args.split('|')
      return argsArr.length && argsArr.includes(value)
    },
    clearErrors() {
      this.errors = []
    },
    clearFieldErrors(field) {
      const inx = this.errors.findIndex((el) => el === field)
      if (inx > -1) {
        this.errors.splice(inx, 1)
      }
    },
  },
}
</script>
